import * as Types from "./../../types/homeTypes";
import * as Actions from "./../message/messageActions";
import { requestPost, requestGet } from "../../../service/request";
import i18next from "i18next";

export const listarPromociones = (usuario, tipo, token, pagina) => {
  let nextPage = pagina || 1;
  return (dispatch) => {
    if (!navigator.onLine) {
      dispatch(
        Actions.showMessage({
          message: i18next.t("reducer.sinAccesoInternet"),
          variant: "warning",
        })
      );
      return;
    }
    const headers = {
      ci: usuario,
      tipo: tipo,
      token: token,
      pagina: nextPage,
    };
    requestGet("/notificacion/obtener/notificaciones", headers, dispatch)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: Types.LIST_PROMOTION,
            principal: response.data.rows,
            totalItem: response.data.count,
            pagina,
          });
        }
      })
      .catch((error) => {
        console.log("log error " + error);
        dispatch(
          Actions.showMessage({
            message: i18next.t("reducer.errorCargarDatos"),
            variant: "error",
          })
        );
      });
  };
};

export const closeNotificacionModal = () => {
  return (dispatch) => {
    dispatch({
      type: Types.LOAD_NOTIFICACION_MODAL,
      status:false
    });
  };
};

export const openNotificacionModal = () => {
  return (dispatch) => {
    dispatch({
      type: Types.LOAD_NOTIFICACION_MODAL,
      status: true
    });
  };
};

export const noMorePage = () => {
  return (dispatch) => {
    dispatch({
      type: Types.NO_MORE_PAGE,
    });
  };
};
