import * as Type from '../../types/homeTypes';


const defaultState = {
    principal: [],
    pagina:1,
    totalItem:0,
    loadMore:false,
    loadNotificaciones: true
};

const concatCustomList = (array1, array2, pagina = 1) => {
    if(array1.length > 0 && pagina > 1){
        return [...array1,...array2];
    }  else{
        return array2;
    }
};

export default function reducer (state = defaultState, action) {  
    switch (action.type) {
        case Type.LIST_PROMOTION:
            return {
                ...state,
                principal:concatCustomList(state.principal, action.principal, action.pagina),
                totalItem:action.totalItem,
                loadMore:action.totalItem,
                pagina:action.pagina,
            };
        case Type.NO_MORE_PAGE:
            return{
                ...state,
                loadMore:false,
            };
        case Type.LOAD_NOTIFICACION_MODAL:
            return{
                ...state,
                loadNotificaciones:action.status
            }   
        default: {
            return state;
        }
    }
};
